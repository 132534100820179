import { Box, Breadcrumbs, IconButton, Typography } from '@mui/material';
import MuiLink from '@mui/material/Link';
import { Link, useLocation, useParams } from 'react-router-dom';
import { useAppSelector } from '../../app/hooks';
import { selectInteraction, selectSession } from '../session/sessionSlice';
import { Session } from '../../types';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useMemo } from 'react';

export interface NavBreadcrumbItem {
    name: string
    url: string
}

const useNavBreadcrumbs = () => {
    const { sessionId, interactionId, username, sessionName, sourceId } = useParams();
    const location = useLocation();
    const session = useAppSelector(selectSession(sessionId, username, sessionName));
    const interaction = useAppSelector(selectInteraction(session?.id, interactionId));

    const { items, isHome } = useMemo(() => {
        const isSources = !!sourceId || location.pathname.endsWith("sources");
        const isHome = location.pathname === "/";
        const getSessionUrl = (session?: Session) => {
            if (sessionId) {
                return `/${session?.id}`
            } else if (session?.published_session) {
                return `/p/${session.published_session?.id}`
            }
            return "/"
        }
        const sessionUrl = getSessionUrl(session);
        const items: NavBreadcrumbItem[] = []

        if (session) {
            items.push({ name: session.name, url: sessionUrl })
        }

        if (interaction) {
            items.push({ name: interaction.name, url: `${sessionUrl}/${interaction?.id}` })
        }

        if (isSources) {
            items.push({ name: "Sources", url: `${sessionUrl}/sources` })
        }

        return { items, isHome };
    }, [interaction, location.pathname, session, sessionId, sourceId])

    return { items, isHome };
}

export const NavBackButton = ({ className }: { className?: string }) => {
    const { items } = useNavBreadcrumbs();

    const lastIndex = items.length - 1;
    const secondToLastUrl = items[lastIndex - 1]?.url || "/";

    return <IconButton component={Link} to={secondToLastUrl} className={className}><ArrowBackIcon /></IconButton>
}

export const NavBreadcrumb = () => {

    const { items, isHome } = useNavBreadcrumbs();

    const lastIndex = items.length - 1;
    const secondToLastUrl = items[lastIndex - 1]?.url || "/";

    return <>
        <Box sx={{ display: "flex", alignItems: "center" }}>
            {!isHome && <IconButton className="back-icon" component={Link} to={secondToLastUrl}><ArrowBackIcon /></IconButton>}
            <Breadcrumbs separator=">" className="nav-breadcrumb">
                {items.map((item, index) => {
                    return <Typography key={index} variant='h2'>
                        <MuiLink component={Link} to={item.url} underline="none" color="primary" >
                            {lastIndex === 0 || index === lastIndex || item.name.length < 6 ? item.name : item.name.substring(0, 6) + "..."}
                        </MuiLink>
                    </Typography>
                })}
            </Breadcrumbs>
        </Box>
    </>
}