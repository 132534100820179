import remarkMath from 'remark-math'
import rehypeKatex from 'rehype-katex'
import remarkGfm from "remark-gfm";

export const remarkPlugins = [remarkMath, remarkGfm]
export const rehypePlugins = [rehypeKatex]

// Taken from https://github.com/danny-avila/LibreChat/blob/ea5140ff0fa53d8659ec4892b1cd2b2dc5aaf942/client/src/utils/latex.ts#L50

export function escapeBrackets(text: string): string {
    const pattern = /(```[\S\s]*?```|`.*?`)|\\\[([\S\s]*?[^\\])\\]|\\\((.*?)\\\)/g;
    return text.replace(
      pattern,
      (
        match: string,
        codeBlock: string | undefined,
        squareBracket: string | undefined,
        roundBracket: string | undefined,
      ): string => {
        if (codeBlock != null) {
          return codeBlock;
        } else if (squareBracket != null) {
          return `$$${squareBracket}$$`;
        } else if (roundBracket != null) {
          return `$${roundBracket}$`;
        }
        return match;
      },
    );
  }
  
  export function escapeMhchem(text: string) {
    return text.replaceAll('$\\ce{', '$\\\\ce{').replaceAll('$\\pu{', '$\\\\pu{');
  }

/**
 * Preprocesses LaTeX content by replacing delimiters and escaping certain characters.
 *
 * @param content The input string containing LaTeX expressions.
 * @returns The processed string with replaced delimiters and escaped characters.
 */
export function preprocessLaTeX(content: string): string {
    // Step 1: Protect code blocks
    const codeBlocks: string[] = [];
    content = content.replace(/(```[\s\S]*?```|`[^`\n]+`)/g, (match, code) => {
      codeBlocks.push(code);
      return `<<CODE_BLOCK_${codeBlocks.length - 1}>>`;
    });
  
    // Step 2: Protect existing LaTeX expressions
    const latexExpressions: string[] = [];
    content = content.replace(/(\$\$[\s\S]*?\$\$|\\\[[\s\S]*?\\\]|\\\(.*?\\\))/g, (match) => {
      latexExpressions.push(match);
      return `<<LATEX_${latexExpressions.length - 1}>>`;
    });
  
    // Step 3: Escape dollar signs that are likely currency indicators
    content = content.replace(/\$(?=\d)/g, '\\$');
  
    // Step 4: Restore LaTeX expressions
    content = content.replace(/<<LATEX_(\d+)>>/g, (_, index) => latexExpressions[parseInt(index)]);
  
    // Step 5: Restore code blocks
    content = content.replace(/<<CODE_BLOCK_(\d+)>>/g, (_, index) => codeBlocks[parseInt(index)]);
  
    // Step 6: Apply additional escaping functions
    content = escapeBrackets(content);
    content = escapeMhchem(content);
  
    return content;
  }