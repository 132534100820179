import { Box, Button, Card, CardActionArea, CardHeader, CardMedia, SxProps, Typography, styled } from "@mui/material"
import { Content, Source } from "../../types"
import { isUrl } from "../../urls"

import SourcePlaceholder from "../../images/source_placeholder.svg"
import React, { useEffect } from "react"
import { Link } from "react-router-dom"
import ForumOutlinedIcon from '@mui/icons-material/ForumOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import { useSourcesPath } from "../../hooks/useUrl"

import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import PublicOutlinedIcon from '@mui/icons-material/PublicOutlined';

const SourceCardLink = ({ children, target, sx, navigate = false }: { target?: string, children: React.ReactNode, sx?: SxProps, navigate?: boolean }) => {

    if (target) {
        return <CardActionArea component={Link} to={target} target={navigate ? "" : "_blank"} sx={sx}>
            {children}
        </CardActionArea>
    }
    return <CardActionArea>
        {children}
    </CardActionArea>
}

const SourceCardButton = ({ children, onClick, sx }: { onClick: () => void, children: React.ReactNode, sx?: SxProps }) => {

    return <CardActionArea component={Button} onClick={onClick} sx={sx}>
        {children}
    </CardActionArea>
}

export const getContentSource = (content: Content) => {
    const { icon = "" } = content.tags
    const source = content.source

    return { icon, source }
}

export const getContentTitle = (content: Content) => {
    return content.tags.title || content.name
}

export const getContentType = (content: Content) => {
    const isSourceUrl = isUrl(content.source)
    return content.tags.type || (isSourceUrl ? "URL" : "FILE")
}

export const getContentSourceShort = (content: Content) => {
    const { source } = getContentSource(content)
    return isUrl(source) ? new URL(source).hostname : source
}

export const DisplaySource = ({ icon, source }: { icon: string, source: string }) => {

    const [error, setError] = React.useState(false)

    useEffect(() => {
        setError(false)
    }, [source])

    const onError = () => {
        if (error) {
            return
        }
        setError(true)
    }

    const isSourceUrl = isUrl(source)
    const displaySource = isSourceUrl ? new URL(source).hostname : source
    let iconUrl = icon

    if (isSourceUrl) {
        iconUrl = iconUrl || new URL(source).origin + "/favicon.ico"
    }

    return <Box className="source-card-display-source" sx={{overflow: "hidden"}}>
        {
            isSourceUrl && !error && <img alt="icon" src={iconUrl} onError={onError} className="tiny-icon" />
        }
        {
            (!isSourceUrl || error) && (isSourceUrl ? <PublicOutlinedIcon className="source-card-icon" fontSize="tiny" color="inherit" /> : <InsertDriveFileOutlinedIcon className="source-card-icon" fontSize="tiny" color="inherit" />)
        }
        <Typography variant="body2" color="text.secondary" noWrap>{displaySource}</Typography>
    </Box>
}

export const SourceMedia = ({ image, sx, fallBack = SourcePlaceholder }: { image: string, sx?: SxProps, fallBack?: string }) => {

    const [img, setImg] = React.useState<string | undefined>(undefined)

    useEffect(() => {
        let _img = image ? image : fallBack
        setImg(_img)
    }, [image, fallBack])

    const errorHandler = () => {
        setImg(fallBack)
    }

    return <CardMedia onError={errorHandler} component="img" image={img} sx={sx} />
}

export const SourceCardTiny = ({ content }: { content: Content }) => {
    const { title = content.name } = content.tags
    const sourceUrl = useSourcesPath();
    const linkTarget = sourceUrl(content.id)

    return <SourceCardTinyComponent title={title} linkTarget={linkTarget} {...getContentSource(content)} />
}
export const SourceCardTinyComponent = ({ title, linkTarget, icon, source }: { title: string, linkTarget: string, icon: string, source: string }) => {
    return <Card sx={{ width: '162px', border: '0', padding: '12px' }} elevation={0}>
        <SourceCardLink target={linkTarget} navigate>
            <Typography color="text.secondary" variant="body2" noWrap>{title}</Typography>
            <DisplaySource icon={icon} source={source} />
        </SourceCardLink>
    </Card>
}

const SourceCardOverlayCard = styled(Card)(({ theme }) => ({
    backgroundColor: theme.palette.background.default,
    border: 0,
    borderBottom: `1px solid ${theme.palette.divider}`,
    padding: theme.spacing(1.5),
    borderRadius: 0,
    margin: 0
}));

export const SourceCardOverlay = ({ content, index }: { content: Content, index: number }) => {
    const { title = content.name } = content.tags
    const sourceUrl = useSourcesPath();
    const linkTarget = sourceUrl(content.id)

    return <SourceCardOverlayCard sx={{}} elevation={0}>
        <SourceCardLink target={linkTarget} navigate>
            <Typography color="text.secondary" variant="body2" noWrap>Source {index + 1}</Typography>
            <Typography variant="body1" mb={0.5} noWrap>{title}</Typography>
            <DisplaySource {...getContentSource(content)} />
        </SourceCardLink>
    </SourceCardOverlayCard>
}

export const SourceCardSmall = ({ source, isCurrentReference=false}: { source: Source, isCurrentReference?: boolean}) => {
    const sourceUrl = useSourcesPath();
    const linkTarget = sourceUrl(source.id)
    return <SourceCardSmallComponent title={source.title} linkTarget={linkTarget} icon={source.icon} source={source.source} image={source.image} isCurrentReference={isCurrentReference} />
}

export const SourceCardSmallComponent = ({ icon, source, image, linkTarget, title, isCurrentReference = false }: { icon: string, source: string, image: string, linkTarget: string, title: string, isCurrentReference?: boolean }) => {

    const sourceMediaSx = { objectFit: 'cover', width: '72px', height: '54px' } as SxProps

    let cardSx = { width: '100%', border: '0', padding: '0' } as SxProps

    if (!isCurrentReference) {
        cardSx = { ...cardSx, backgroundColor: 'transparent' }
    }

    return <Card sx={cardSx} elevation={0}>
        <SourceCardLink target={linkTarget} navigate>
            <CardHeader sx={{ padding: '0' }} title={title} avatar={<SourceMedia image={image} sx={sourceMediaSx} />}
                subheaderTypographyProps={{ noWrap: false, sx: { width: '100%;' } }}
                titleTypographyProps={{ color: "text.secondary", noWrap: false, sx: { width: '100%' } }}
                subheader={<DisplaySource icon={icon} source={source} />}></CardHeader>
        </SourceCardLink>
    </Card>
}

export const SourceCardLargeComponent = ({ source, onClick}: { source: Source, onClick: () => void }) => {
    const sx = { objectFit: 'cover', flex: '1 1 40%', overflow: 'hidden' } as SxProps

    return <Card sx={{ padding: 0 }} elevation={0}>
        <SourceCardButton onClick={onClick} sx={{ display: 'flex', flexDirection: 'column', gap: 0 }}>
            <SourceMedia image={source.image} sx={sx} />
            <Box className="source-card-large-content">
                <Box className="source-card-large-content-body">
                    <Typography variant="h4" className="max-lines-2">
                            {source.title}
                    </Typography>
                    <Box className="spacing-small" />
                    <Typography variant="body1" color="text.secondary" className="max-lines-3">
                            {source.description}
                    </Typography>
                </Box>
                <Box className="source-card-large-content-footer">
                    <Box className="source-card-large-content-footer-item" color="text.secondary">
                        <DisplaySource icon={source.icon} source={source.source} />
                    </Box>
                    <Box className="source-card-large-content-footer-icons">
                        <Box className="source-card-large-content-footer-item" color="text.secondary">
                            <ForumOutlinedIcon fontSize="small" color="inherit" />
                            <Typography variant="body2" color="inherit">
                                {source.references}
                            </Typography>
                        </Box>
                        <Box className="source-card-large-content-footer-item" color="text.secondary">
                            <VisibilityOutlinedIcon fontSize="small" color="inherit" />
                            <Typography variant="body2" color="inherit">
                                24
                            </Typography>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </SourceCardButton>
    </Card>
}