import { Box, Button, Divider, IconButton, List, ListItemButton, ListItemIcon, ListItemText, styled } from "@mui/material"
import SessionsLoaderComponent from "../features/session/sessionsLoaderComponent"
import { SearchEverythingComponent } from "../features/common/searchEverythingComponent"
import AddIcon from '@mui/icons-material/Add';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import LanguageOutlinedIcon from '@mui/icons-material/LanguageOutlined';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import InsertLinkOutlinedIcon from '@mui/icons-material/InsertLinkOutlined';
import { useAppDispatch, useAppSelector } from "../app/hooks";
import { fetchCreateSession, selectAllSessions } from "../features/session/sessionSlice";
import { CurrentUserAvatar } from "../components/currentUserAvatar";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { NavBreadcrumb } from "../features/common/navBreadcrumb";
import { CreateSessionModal, CreateSessionModalContext, CreateSessionRedirector } from "../components/modals/createSessionModal";
import { useModal } from "../hooks/useModal";
import React, { useMemo } from "react";
import classNames from "classnames";
import { useSearchEverything } from "../features/search";
import { getSessionsSortedByLastUpdated } from "../sessionUtils";
import { getMostPublicSessionPath } from "../hooks/useUrl";
import { useAuth } from "../auth";
import { GuestErrorModal } from "../components/modals/guestErrorModal";
import { Logo } from "../logo";
import { Session, User } from "../types";
import { CollapsibleList } from "../components/collapsibleList";


export const InternalPageContentBodyHeader = ({ children, className }: { children?: React.ReactNode, className?: string }) => {
    return <Box className={`internal-page-content-body-header ${className ? className : ''}`}>
        <NavBreadcrumb />
        <Box className="internal-page-content-body-header-actions">
            {children}
        </Box>
    </Box>
}

const InternalPageHeader = ({ onCreate }: { onCreate: () => void }) => {
    const [isSearching, setIsSearching] = React.useState(false);

    const sessions = useAppSelector(selectAllSessions)
    const { query, setQuery, searchResults } = useSearchEverything(sessions)

    const searchInputRef = React.useRef<HTMLInputElement>(null);

    const navigate = useNavigate();
    const location = useLocation();

    const isHome = location.pathname === "/";
    const isHub = location.pathname === "/hub";
    const isSearch = location.pathname === "/search"

    const showAvatar = isHome || isHub
    const showHub = isHome
    const showCreate = isHome
    const showSearch = !isSearch

    const onLogoClicked = () => navigate('/');

    const onSearchClicked = () => {
        setIsSearching(true);
        setTimeout(() => searchInputRef.current?.focus(), 10);
    }

    const onSearchBlur = () => {
        setTimeout(() => {
            setIsSearching(false)
        }, 10);
    }

    const onKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            navigate(`/search?q=${query}`)
        } else if (event.key === 'Escape') {
            setIsSearching(false);
            setQuery('');
        }
    }

    const searchEverythingProps = {
        query,
        setQuery,
        searchResults,
        inputRef: searchInputRef,
        onBlur: onSearchBlur,
        onKeyDown: onKeyDown
    }

    return <Box className="internal-page-header" component="header">
        <Box className={classNames("internal-page-header-home", { "desktop-only": isSearching })} onClick={onLogoClicked}>
            <Box className="internal-page-header-logo" onClick={onLogoClicked}>
                <Logo />
            </Box>
        </Box>
        {
            showSearch &&
            <Box className={classNames({ "desktop-only": !isSearching })} sx={{ flex: '1' }}>
                <SearchEverythingComponent {...searchEverythingProps} />
            </Box>
        }
        <Box className="internal-page-header-buttons">
            {
                !isSearching && showSearch &&
                <IconButton className="mobile-only-flex" onClick={onSearchClicked}><SearchOutlinedIcon fontSize="small" /></IconButton>
            }
            {
                showHub && !isSearching &&
                <IconButton className="mobile-only-flex" component={Link} to="/hub"><LanguageOutlinedIcon fontSize="small" /></IconButton>
            }{
                showCreate &&
                <>
                    <Button className="desktop-only" variant="contained" onClick={onCreate} color="contrast"><AddIcon fontSize="small" /> Create</Button>
                    {!isSearching &&
                        <Button className="mobile-only-flex" sx={{ minWidth: 0 }} variant="contained" onClick={onCreate}><AddIcon fontSize="small" /></Button>
                    }
                </>
            }
            {
                showAvatar && !isSearching &&
                <Box className="mobile-only">
                    <CurrentUserAvatar disableName />
                </Box>
            }
        </Box>
    </Box>
}

export const InternalPageContent = ({ children, onCreate }: { children: React.ReactNode, onCreate: () => void }) => {
    return <Box className="internal-page-content">
        {children}
    </Box>
}

const SecondaryList = styled(List)(({ theme }) => ({
    color: theme.palette.text.secondary
}));

const SessionListIcon = ({ session }: { session: Session }) => {

    const sx = {fontSize: "1.2em"}

    let icon = <LockOutlinedIcon sx={sx} />

    if (session.published_session) {
        icon = <LanguageOutlinedIcon sx={sx} />
    } else if (session.is_link_public) {
        icon = <InsertLinkOutlinedIcon sx={sx} />
    }

    return <ListItemIcon sx={{ color: 'inherit' }}>
        {icon}
    </ListItemIcon>
}

const DrawerSessionsList = ({ sessions, title, storageKey}: { sessions: Session[], title: string, storageKey: string}) => {

    const onHeaderClicked = (newState: boolean) => {
        localStorage.setItem(storageKey, newState ? '1' : '0');
    }

    const sessionsOpen = localStorage.getItem(storageKey) === '1';

    return <SecondaryList className="internal-page-left-drawer-sessions hide-scrollbar">
        <CollapsibleList title={title} onClick={onHeaderClicked} defaultOpen={sessionsOpen}>
            {sessions.map((session) => <ListItemButton style={{paddingBlock: "4px", paddingLeft: "32px"}} key={session.id} component={Link} to={getMostPublicSessionPath(session)}>
                <SessionListIcon session={session} />
                <ListItemText primaryTypographyProps={{ noWrap: true }} color="primary">{session.name}</ListItemText>
            </ListItemButton>)}
        </CollapsibleList>
    </SecondaryList>
}

const LeftDrawer = ({ user }: { user?: User }) => {

    const sessions = useAppSelector(selectAllSessions);
    const sortedSessions = useMemo(() => getSessionsSortedByLastUpdated(sessions), [sessions]);
    const favoriteSessions = useMemo(() => sortedSessions.filter((session) => session.is_favorite), [sortedSessions]);
    const recentlyUpdatedSessions = useMemo(() => sortedSessions.filter((session) => !session.is_favorite && !session.is_hub_session).slice(0, 5), [sortedSessions]);
    const createdByUserSessions = useMemo(() => sortedSessions.filter((session) => session.created_by === user?.id), [sortedSessions, user]);
    const sharedWithUser = useMemo(() => sortedSessions.filter((session) => user && session.created_by !== user?.id && session.members.map(member => member.user.id).includes(user.id)), [sortedSessions, user]);

    const hasFavoriteSessions = favoriteSessions.length > 0;

    return <Box className="internal-page-left-drawer" component="nav">
        <Box className="internal-page-left-drawer-actions">
            <Button sx={{ justifyContent: 'left' }} size="large" variant="text" aria-label="home" color="primary" fullWidth component={Link} to="/"><HomeOutlinedIcon />Home</Button>
            <Button sx={{ justifyContent: 'left' }} size="large" aria-label="community" fullWidth component={Link} to="/hub" variant="text" color="primary"><LanguageOutlinedIcon />Community</Button>
        </Box>
        {hasFavoriteSessions && <DrawerSessionsList sessions={favoriteSessions} title="Favorites" storageKey="spacesList.favorites"/>}
        <DrawerSessionsList sessions={recentlyUpdatedSessions} title="Recently updated" storageKey="spacesList.recentlyUpdated"/>
        <DrawerSessionsList sessions={sharedWithUser} title="Shared with you" storageKey="spacesList.sharedWithUser"/>
        <DrawerSessionsList sessions={createdByUserSessions} title="Your spaces" storageKey="spacesList.createdByUser"/>
        <Box className="internal-page-left-drawer-footer">
            <CurrentUserAvatar />
        </Box>
    </Box>
}

export const InternalPage = ({ children }: { children: React.ReactNode }) => {

    const createSessionModal = useModal();
    const guestErrorModal = useModal();

    const { isGuest, user } = useAuth();

    const dispatch = useAppDispatch();

    const onAccept = (name: string, shortDescription?: string) => {
        dispatch(fetchCreateSession({ name, shortDescription }))
        createSessionModal.onClose();
    }

    const onCreateClicked = () => {
        if (isGuest) {
            guestErrorModal.onClick();
            return;
        }

        createSessionModal.onClick();
    }

    return <>
        {isGuest && <GuestErrorModal open={guestErrorModal.open} onClose={guestErrorModal.onClose} onAccept={guestErrorModal.onClose} />}
        <InternalPageHeader onCreate={onCreateClicked} />
        <Box className="internal-page">
            <CreateSessionRedirector />
            <CreateSessionModalContext.Provider value={createSessionModal}>
                <SessionsLoaderComponent />
                <CreateSessionModal open={createSessionModal.open} onAccept={onAccept} onClose={createSessionModal.onClose} />
                <LeftDrawer user={user} />
                <Divider orientation="vertical" className="desktop-divider" />
                <InternalPageContent onCreate={onCreateClicked}>
                    {children}
                </InternalPageContent>
            </CreateSessionModalContext.Provider>
        </Box>
    </>
}