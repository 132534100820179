import { InteractionEvent } from "../../../types"
import React, { useEffect } from "react"
import { render_iframe_url } from "../../../App"

export const ReactComponentToolComponent = ({ tool }: { tool: InteractionEvent }) => {

    const [height, setHeight] = React.useState<number>(200);
    const [ready, setReady] = React.useState<boolean>(false);
    const iframeRef = React.useRef<HTMLIFrameElement>(null);

    const sendCode = ({ code, isReady }: { code?: string, isReady?: boolean }) => {
        if (iframeRef.current && (isReady || ready)) {
            if (iframeRef.current.contentWindow) {
                iframeRef.current.contentWindow.postMessage({ code }, render_iframe_url)
            }
        }
    }


    useEffect(() => {
        sendCode({ code: tool.response })
    }, [tool.response])

    useEffect(() => {
        window.addEventListener("message", (event: MessageEvent) => {
            if (event.data.type === "render") {
                setHeight(event.data.height)
            }
            if (event.data.type === "ready") {
                sendCode({ code: tool.response, isReady: true })
                setReady(true)
            }
        });
        return () => {
            window.removeEventListener("message", (event: MessageEvent) => {
            });
        }
    }, [])

    return <iframe title="React" id="render-iframe"
        ref={iframeRef}
        src={render_iframe_url} style={{ width: "100%", height: `${height + 32}px`, border: 0, overflow: "hidden" }} sandbox="allow-scripts allow-same-origin"></iframe>
}
