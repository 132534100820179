import { Box, Button, CircularProgress, Divider, IconButton, Popover, Tab, TabProps, Tabs, TextField, Typography, styled, useColorScheme, useMediaQuery, useTheme } from "@mui/material";
import { InternalPage, InternalPageContentBodyHeader } from "./internalPage";
import { SessionState, useSession } from "../hooks/useSession";
import { Session, SessionBasePromptMaxLength, SessionNameMaxLength, SessionShortDescriptionMaxLength } from "../types";
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import AddLinkOutlinedIcon from '@mui/icons-material/AddLinkOutlined';
import InsertLinkIcon from '@mui/icons-material/InsertLink';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import ShareOutlinedIcon from '@mui/icons-material/ShareOutlined';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import HeartBrokenOutlinedIcon from '@mui/icons-material/HeartBrokenOutlined';
import LanguageOutlinedIcon from '@mui/icons-material/LanguageOutlined';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import InsertLinkOutlinedIcon from '@mui/icons-material/InsertLinkOutlined';
import { InteractionCardLargeComponent } from "../components/cards/interactionCard";
import { SourceCardSmall, SourceCardTiny } from "../components/cards/sourceCard";
import { MembersAvatarGroup, getMemberInfoById } from "../members";
import { Link, useNavigate, useParams } from "react-router-dom";
import { OutlinedButton } from "../components/outlinedButton";
import React, { createContext, useContext, useMemo, useState } from "react";
import { FileInput } from "../features/fileUpload";
import { UploadLinkModal } from "../components/modals/uploadLinkModal";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import { fetchDeleteSession, fetchFavoriteSession, fetchQueryNewInteraction, fetchUpdateSession, fetchUploadFile, selectFileUploadState, selectUpdateSessionState } from "../features/session/sessionSlice";
import SendIcon from '@mui/icons-material/Send';
import { useModal } from "../hooks/useModal";
import { ConfirmModal, ConfirmModalBody, ConfirmModalStrong, deleteAcceptText, deleteTitle } from "../components/modals/confirmModal";
import { useInteractionPath, useSessionPath, useSourcesPath } from "../hooks/useUrl";
import { HorizontalCarrouselComponent } from "../components/horizontalCarousel";
import { ShareSessionModal } from "../components/modals/shareSessionModal";
import { Bubble, HeaderPublicBubble } from "../components/bubble";
import { SessionSocketConnector } from "../connectors/sessionSocketConnector";
import { useUploadUrl } from "../hooks/useUploadUrl";
import { LinkedSessionCardTiny } from "../components/cards/linkedSessionCard";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";

import Markdown from "react-markdown";
import { useAuth } from "../auth";
import { ModalFormField } from "../components/modals/modal";
import { usePopover } from "../hooks/usePopover";
import { ActionsPopover, ActionsPopoverItem } from "../components/popover/actionsPopover";
import { userIsSessionAdmin } from "../sessionUtils";
import { GuestErrorModal } from "../components/modals/guestErrorModal";
import { spacePromptEnabled } from "../App";
import { useSources } from "./sources";
import { ArrowBackOutlined } from "@mui/icons-material";
import { BannerLarge, BannerMembers } from "../components/banner";
import { SessionUrlTextField } from "../components/urlTextField";
import { MDXEditor, headingsPlugin, toolbarPlugin, listsPlugin, quotePlugin, linkPlugin, linkDialogPlugin, imagePlugin, tablePlugin, thematicBreakPlugin, codeBlockPlugin, codeMirrorPlugin, diffSourcePlugin, markdownShortcutPlugin, UndoRedo, BoldItalicUnderlineToggles, Separator, CodeToggle, ListsToggle, CreateLink, InsertImage, InsertTable, InsertThematicBreak, InsertCodeBlock, BlockTypeSelect, DiffSourceToggleWrapper } from '@mdxeditor/editor'
import { basicDark } from 'cm6-theme-basic-dark'
import { Controller, useForm } from "react-hook-form";
import { htmlPlugin } from "../components/markdownEditor";
import Dropzone, { DropEvent, FileRejection } from "react-dropzone";
import { ACCEPTED_FILE_TYPES, MAX_FILE_SIZE } from "../features/common/fileUploadDropzone";
import { enqueueSnackbar } from "../features/notifications/notificationsSlice";
import { FileAddOutlined } from "../icons/FileAddOutlined";
import { preprocessLaTeX, rehypePlugins, remarkPlugins } from "../features/markdown";

const themes = {
    "0": "linear-gradient(rgba(148, 212, 195, 1),rgba(119, 181, 221, 1))",
    "1": "linear-gradient(rgba(172, 229, 145, 1), rgba(84, 171, 109, 1))",
    "2": "linear-gradient(rgba(227, 173, 202, 1), rgba(220, 124, 135, 1))",
    "3": "linear-gradient(rgba(165, 162, 219, 1), rgba(123, 116, 231, 1))",
    "4": "linear-gradient(rgba(223, 223, 243, 1), rgba(183, 180, 233, 1))",
    "5": "linear-gradient(rgba(250, 224, 175, 1), rgba(252, 175, 142, 1))",
}

const themeKeys = Object.keys(themes);

export const SessionPageHeaderActions = ({ session }: { session: Session }) => {
    const { isAuthenticated } = useAuth();

    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const popover = usePopover();

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const { user } = useAuth();
    const canEdit = userIsSessionAdmin(session, user?.id)

    const shareModal = useModal();
    const deleteModal = useModal();

    const sessionUrl = useSessionPath();

    const isPublished = session.published_session !== null;

    const canDelete = !isPublished && canEdit;
    const canShare = isAuthenticated;
    const canFavorite = isAuthenticated;

    const isFavorite = session.is_favorite;


    const onDelete = () => {
        if (session.id) {
            dispatch(fetchDeleteSession(session.id));
            navigate("/");
        }
    }

    const shareModalProps = {
        open: shareModal.open,
        onClose: shareModal.onClose,
        session: session,
        onAccept: shareModal.onClose
    }

    const deleteSessionModalProps = {
        open: deleteModal.open,
        onClose: deleteModal.onClose,
        onAccept: onDelete,
        title: deleteTitle,
        icon: <DeleteOutlineIcon />,
        body: <ConfirmModalBody>Are you sure you want to delete the space <ConfirmModalStrong>{session.name}</ConfirmModalStrong>? This action cannot be reversed.</ConfirmModalBody>,
        acceptText: deleteAcceptText,
    }


    const popoverProps = {
        open: popover.open,
        anchorEl: popover.anchorEl,
        anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center',
        } as const,
        transformOrigin: {
            vertical: 'top',
            horizontal: 'center',
        } as const
    }

    const onPopoverInfoClicked = () => {
        navigate(`${sessionUrl}/details`);
        popover.handleClose();
    }

    const onPopoverShareClicked = () => {
        shareModal.onClick();
        popover.handleClose();
    }

    const onPopoverDeleteClicked = () => {
        deleteModal.onClick();
        popover.handleClose();
    }

    const onFavoriteClicked = () => {
        const newFavorite = !isFavorite;
        dispatch(fetchFavoriteSession({ sessionId: session.id, shouldFavorite: newFavorite }));
    }

    return <>
        <ShareSessionModal {...shareModalProps} />
        <ConfirmModal {...deleteSessionModalProps} />
        <ActionsPopover {...popoverProps}>
            {canShare && <ActionsPopoverItem onClick={onPopoverShareClicked} text="Share" icon={<ShareOutlinedIcon />} />}
            <ActionsPopoverItem onClick={onPopoverInfoClicked} text="Info" icon={<InfoOutlinedIcon />} />
            {canFavorite && <ActionsPopoverItem onClick={onFavoriteClicked} text={isFavorite ? "Unfavorite" : "Favorite"} icon={isFavorite ? <HeartBrokenOutlinedIcon /> : <FavoriteBorderIcon />} />}
            {
                canDelete ?
                    <>
                        <Divider />
                        <ActionsPopoverItem onClick={onPopoverDeleteClicked} text="Delete" color="error" icon={<DeleteOutlineIcon color="error" />} />
                    </>
                    : <></>
            }
        </ActionsPopover >
        {
            isMobile ?
                <IconButton onClick={popover.handleClick} aria-label="more-actions" color="primary"><MoreVertIcon fontSize="small" /></IconButton>
                :
                <>
                    {canShare && <Button variant="contained" color="contrast" aria-label="share" size="small" onClick={shareModal.onClick}>Share</Button>}
                    <IconButton aria-label="information" component={Link} to={`${sessionUrl}/info`}><InfoOutlinedIcon /></IconButton>
                    {canFavorite && <IconButton aria-label="favorite" onClick={onFavoriteClicked}>{isFavorite ? <FavoriteIcon /> : <FavoriteBorderIcon />}</IconButton>}
                    {
                        canDelete ?
                            <IconButton aria-label="delete" color="error" onClick={deleteModal.onClick}><DeleteOutlineIcon fontSize="small" /></IconButton>
                            : <></>
                    }
                </>
        }
    </>
}


export const SessionPageHeader = ({ session }: { session: Session }) => {
    const isPublished = !!session.published_session;

    return <InternalPageContentBodyHeader >
        {
            isPublished ?
                <HeaderPublicBubble /> : <MembersAvatarGroup members={session.members} max={5} />
        }
        <SessionPageHeaderActions session={session} />
    </InternalPageContentBodyHeader >
}


const SessionInteractionsGrid = ({ session }: { session: Session }) => {
    const interactions = session?.interactions;
    return <Box className="session-interactions-grid">
        {interactions?.map((interaction, idx) => <InteractionCardLargeComponent key={interaction.id} interaction={interaction} session={session} />)}
    </Box>
}

interface SessionPageFooterProps {
    session: Session
    onAddFileClicked?: () => void
    onFileSelected: (file: File) => void
    onAddLink: () => void
    displayOnDesktop?: boolean
}

const SessionPageFooterNoContent = ({ onFileSelected, onAddLink, onAddFileClicked, displayOnDesktop }: SessionPageFooterProps) => {
    return <Box className={`internal-page-footer internal-page-footer-no-content ${displayOnDesktop ? "" : "mobile-only-flex"}`}>
        <Button onClick={onAddFileClicked ? onAddFileClicked : () => { }} component="label" aria-label="Add file" title="Add file" color="paper" fullWidth variant="contained" startIcon={<FileAddOutlined />} >
            Add file
            {!onAddFileClicked && <FileInput onSelected={onFileSelected} />}
        </Button>
        <Button onClick={onAddLink} aria-label="Add link" title="Add link" color="paper" fullWidth variant="contained" startIcon={<AddLinkOutlinedIcon />}>Add link</Button>
    </Box>
}

const SessionPageTextField = ({ session }: { session: Session }) => {

    const [query, setQuery] = React.useState("");
    const queryIsValid = query !== "";

    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const interactionUrl = useInteractionPath();

    const onNewInteractionCreated = (interactionId: string) => {
        navigate(interactionUrl(interactionId, session.id));
    }

    const onKeyDown = (e: React.KeyboardEvent) => {
        if (e.key === 'Enter') {
            onSubmit(query);
        }
    }

    const onSubmit = (query: string) => {
        if (queryIsValid)
            dispatch(fetchQueryNewInteraction({ sessionId: session.id, query: query, onNewInteractionCreated: onNewInteractionCreated }))
        setQuery("");
    }

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setQuery(e.target.value);
    }

    return <TextField
        label=""
        placeholder="Interact with your content..."
        variant="outlined"
        fullWidth
        onKeyDown={onKeyDown}
        value={query}
        onChange={onChange}
        InputProps={{
            endAdornment: <IconButton onClick={() => onSubmit(query)} disabled={!queryIsValid} color="primary"><SendIcon /></IconButton>
        }}
    />
}

const SessionPageFooterDefault = ({ session, onFileSelected, onAddLink, onAddFileClicked, displayOnDesktop }: SessionPageFooterProps) => {
    return <Box className="internal-page-footer">
        <IconButton title="Add file" className={displayOnDesktop ? "" : "mobile-only"} onClick={onAddFileClicked ? onAddFileClicked : () => { }} aria-label="Add file" color="primary" component="label">
            <FileAddOutlined />
            {!onAddFileClicked && <FileInput onSelected={onFileSelected} />}
        </IconButton>
        <IconButton title="Add link" className={displayOnDesktop ? "" : "mobile-only"} onClick={onAddLink} aria-label="Add link" color="primary"><AddLinkOutlinedIcon /></IconButton>
        <SessionPageTextField session={session} />
    </Box>
}

export const SessionPageFooter = ({ session, emptyOnly, displayOnDesktop = false, hideFooter = false }: { session: Session, emptyOnly?: boolean, displayOnDesktop?: boolean, hideFooter?: boolean }) => {

    const dispatch = useAppDispatch();
    const { isGuest } = useAuth();
    const uploadUrl = useUploadUrl();

    const uploadLinkModal = useContext(UploadLinkContext);
    const guestErrorModal = useContext(GuestErrorContext);

    const handleCloseUploadLinkModal = () => {
        uploadLinkModal.onClose();
    }

    const doUploadLink = (url: string) => {
        uploadUrl(url, session.id);
        uploadLinkModal.onClose();
    }

    const hasContent = (session.contents.length + session.linked_sessions.length) > 0;

    const onFileSelected = (file: File) => {
        if (isGuest) {
            guestErrorModal.onOpen();
            return;
        }

        dispatch(fetchUploadFile({ sessionId: session.id, file: file }))
    }

    const Component = hasContent && !emptyOnly ? SessionPageFooterDefault : SessionPageFooterNoContent;

    const onAddFileClicked = () => {
        if (isGuest) {
            guestErrorModal.onOpen();
        }
    }

    return <>
        {isGuest && <GuestErrorModal open={guestErrorModal.open} onClose={guestErrorModal.onClose} onAccept={guestErrorModal.onClose} />}
        <UploadLinkModal open={uploadLinkModal.open} onClose={handleCloseUploadLinkModal} onAccept={doUploadLink} />
        {
            !hideFooter && <Component displayOnDesktop={displayOnDesktop} onAddLink={uploadLinkModal.onOpen} onFileSelected={onFileSelected} session={session} onAddFileClicked={isGuest ? onAddFileClicked : undefined} />
        }
    </>
}

const SourcesList = ({ session }: { session: Session }) => {

    const sources = useSources(session);

    return <Box className="sources-list">
        {sources.map((source) => <SourceCardSmall key={source.id} source={source} />)}
    </Box>
}

const SessionPageMobileSources = ({ session }: { session: Session }) => {

    const [sourcesOpen, setSourcesOpen] = useState<boolean>(false);
    const sourcesUrl = useSourcesPath();
    const navigate = useNavigate();
    const url = sourcesUrl()

    const toggleSources = () => {
        setSourcesOpen(!sourcesOpen)
    }

    const numSources = session.contents.length + session.linked_sessions.length;

    const buttonText = sourcesOpen ? "Hide sources" : `Based on ${numSources} sources`;

    return <Box className="session-page-sources-section mobile-only">
        <Box sx={{ display: "flex", gap: "8px", justifyContent: "space-between" }}>
            <OutlinedButton onClick={toggleSources}><InsertLinkIcon />{buttonText}</OutlinedButton>
            {sourcesOpen && <Button variant="contained" color="contrast" onClick={() => navigate(url)}>View all</Button>}
        </Box>
        {sourcesOpen &&
            <Box sx={{ paddingTop: "8px" }}>
                <HorizontalCarrouselComponent>
                    {session.contents.map((content) => <SourceCardTiny content={content} key={content.id} />)}
                    {session.linked_sessions.map((linkedSession) => <LinkedSessionCardTiny key={linkedSession} sessionId={linkedSession} />)}
                </HorizontalCarrouselComponent>
            </Box>
        }
    </Box>
}

const SessionPageBannerLarge = ({ session }: { session: Session }) => {
    const shareModal = useModal();
    const deleteModal = useModal();
    const popover = usePopover();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { isAuthenticated, user } = useAuth();

    const isPublished = session.published_session !== null;

    const canEdit = userIsSessionAdmin(session, user?.id)
    const canDelete = !isPublished && canEdit;
    const canShare = isAuthenticated;
    const canFavorite = isAuthenticated;
    const isFavorite = session.is_favorite;

    const createdBy = getMemberInfoById(session.created_by, session.members);

    const onDelete = () => {
        if (session.id) {
            dispatch(fetchDeleteSession(session.id));
            navigate("/");
        }
    }

    const shareModalProps = {
        open: shareModal.open,
        onClose: shareModal.onClose,
        session: session,
        onAccept: shareModal.onClose
    }

    const deleteSessionModalProps = {
        open: deleteModal.open,
        onClose: deleteModal.onClose,
        onAccept: onDelete,
        title: deleteTitle,
        icon: <DeleteOutlineIcon />,
        body: <ConfirmModalBody>Are you sure you want to delete the space <ConfirmModalStrong>{session.name}</ConfirmModalStrong>? This action cannot be reversed.</ConfirmModalBody>,
        acceptText: deleteAcceptText,
    }

    const onFavoriteClicked = () => {
        const newFavorite = !isFavorite;
        dispatch(fetchFavoriteSession({ sessionId: session.id, shouldFavorite: newFavorite }));
    }

    const onPopoverDeleteClicked = () => {
        deleteModal.onClick();
        popover.handleClose();
    }

    const [shareIcon, shareState] = useMemo(() => {
        if (session.published_session) {
            return [<LanguageOutlinedIcon fontSize="small" opacity="inherit" />, "Public"];
        }
        if (session.is_link_public) {
            return [<InsertLinkOutlinedIcon fontSize="small" opacity="inherit" />, "Shared"];
        }
        return [<LockOutlinedIcon fontSize="small" opacity="inherit" />, "Private"]
    }, [session])

    const hasMoreActions = canDelete;
    const storedTheme = session.theme || "0";
    // @ts-ignore
    const background = themeKeys.includes(storedTheme) ? themes[storedTheme] : themes["0"];
    const description = session.short_description || session.description?.slice(0, 100) || "";
    const hasDescription = description && description.length > 0;

    return <BannerLarge background={background}>
        <ShareSessionModal {...shareModalProps} />
        <ConfirmModal {...deleteSessionModalProps} />
        {
            canDelete && <Popover open={popover.open} anchorEl={popover.anchorEl} onClose={popover.handleClose} anchorOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}>
                <ActionsPopoverItem onClick={onPopoverDeleteClicked} text="Delete" color="error" icon={<DeleteOutlineIcon color="error" />} />
            </Popover>
        }
        <Button className="mobile-only-flex" style={{ justifyContent: "flex-start", paddingInline: 0 }} component={Link} to="/" color="inherit" aria-label="back" fullWidth><ArrowBackOutlined color="inherit" fontSize="small" /></Button>
        <Box sx={{ display: "flex", flexDirection: "row" }}>
            <Typography variant="h1" sx={{ flex: "1 1 auto" }}>{session.name}</Typography>

        </Box>
        {hasDescription &&
            <Typography variant="body1" component="div">
                {description}
            </Typography>}
        <Box sx={{ display: "flex", alignItems: "flex-start" }}>
            <Typography sx={{ opacity: 0.7 }} component="span" variant="body2">{shareIcon}
                <span>&nbsp;</span>
            </Typography>
            <Typography sx={{ opacity: 0.7 }} component="span" variant="body2">{shareState} space created by
                <span>&nbsp;</span>
            </Typography>
            <Typography component="span" variant="body2"><b>{createdBy?.username}</b></Typography>
        </Box>
        <Box className="session-page-banner-large-info">
            <Box sx={{ display: "flex", gap: "8px" }}>
                {canFavorite && <Button variant="contained" color="secondary" aria-label="Favorite" startIcon={isFavorite ? <FavoriteIcon /> : <FavoriteBorderIcon />} onClick={onFavoriteClicked}>Favorite</Button>}
                {canShare && <Button variant="outlined" color="inherit" aria-label="Share" startIcon={<ShareOutlinedIcon />} onClick={shareModal.onClick}>Share</Button>}
                {hasMoreActions && <IconButton onClick={popover.handleClick} aria-label="More actions" color="inherit"><MoreVertIcon fontSize="small" /></IconButton>}
            </Box>
            <Box className="session-page-banner-large-info-inner">
                <Bubble text={`${session.statistics.n_favorites} favorites`} typographyProps={{ color: "inherit" }} icon={<FavoriteBorderIcon fontSize="tiny" color="inherit" />} noBorder />
                <Box className="session-page-banner-large-info-members">
                    <BannerMembers members={session.members} />
                </Box>
            </Box>
        </Box>
    </BannerLarge>
}

type SessionPageTab = "interactions" | "details" | "settings";

const CustomTabs = styled(Tabs)(({ theme }) => ({
    '& .MuiTabs-indicator': {
        backgroundColor: theme.palette.text.secondary,
    },
    '& .MuiTabs-flexContainer': {
        gap: "24px"
    }
}));


const CustomTab = ({ label, disableRipple, sx, ...props }: TabProps) => {
    sx = { ...sx, paddingInline: "0", paddingBottom: "8px", minWidth: "0" }
    return <Tab sx={sx} disableRipple {...props} label={<Typography variant="h4">{label}</Typography>} />
}

const SessionPageContentNav = ({ value, onChange, showSettings = true }: { value: SessionPageTab, showSettings: boolean, onChange: (event: React.SyntheticEvent, value: SessionPageTab) => void }) => {

    return <Box className="session-page-content-nav">
        <CustomTabs onChange={onChange} value={value} TabIndicatorProps={{ sx: { height: "1px" } }}>
            <CustomTab label="Interactions" value="interactions" />
            <CustomTab label="Details" value="details" />
            {showSettings && <CustomTab label="Settings" value="settings" />}
        </CustomTabs>
    </Box>
}

const UploadLinkContext = createContext({ open: false, onOpen: () => { }, onClose: () => { } });
const GuestErrorContext = createContext({ open: false, onOpen: () => { }, onClose: () => { } });

export const UploadProviders = ({ children }: { children: React.ReactNode }) => {
    const uploadLinkModal = useModal();
    const guestErrorModal = useModal();

    return <UploadLinkContext.Provider value={{ open: uploadLinkModal.open, onOpen: uploadLinkModal.onClick, onClose: uploadLinkModal.onClose }}>
        <GuestErrorContext.Provider value={{ open: guestErrorModal.open, onOpen: guestErrorModal.onClick, onClose: guestErrorModal.onClose }}>
            {children}
        </GuestErrorContext.Provider>
    </UploadLinkContext.Provider>
}

const SessionPageBody = ({ tab }: { tab?: SessionPageTab }) => {

    const { session, state } = useSession();

    const { user } = useAuth();
    const isAdmin = !!session && userIsSessionAdmin(session, user?.id);

    const { sourceId } = useParams();

    const navigate = useNavigate();

    const currentTab = tab || "interactions";


    if (state === 'failed') {
        return <Typography variant="h1">Sorry, this space does not exist.</Typography>
    }

    if (state === 'loading') {
        return <CircularProgress />
    }

    const onNavChange = (event: React.SyntheticEvent, value: SessionPageTab) => {
        const nextLocation = value === "interactions" ? "" : value;
        if (sourceId) {
            navigate(`../../${nextLocation}`, { relative: "path" })
        } else if (currentTab === "interactions") {
            navigate(`${nextLocation}`)
        }
        else {
            navigate(`../${nextLocation}`, { relative: "path" })
        }
    }

    return <>
        {session?.id && <SessionSocketConnector sessionId={session?.id} sessionIsLoaded={!!session} />}
        <UploadProviders >
            <Box className="internal-page-center">
                <Box sx={{ overflowY: "auto", position: "relative", display: "flex", flexDirection: "column", flex: "1 1 auto" }} className="hide-scrollbar">
                    {session && <SessionPageBannerLarge session={session} />}
                    {session && <SessionPageContentNav onChange={onNavChange} value={currentTab} showSettings={isAdmin} />}
                    {session && currentTab === "interactions" && <SessionPageInteractionsTab session={session} state={state} />}
                    {session && currentTab === "details" && <SessionPageDetailsTab session={session} />}
                    {session && isAdmin && currentTab === "settings" && <SessionPageSettingsTab session={session} />}
                </Box>
                {session &&
                    <>
                        <Divider />
                        <SessionPageFooter session={session} hideFooter={currentTab !== "interactions"} />
                    </>
                }
            </Box>
            {session && <SessionPageInteractionsTabDrawer session={session} />}
        </UploadProviders>
    </>
}

const SessionPageInteractionsTabDrawer = ({ session }: { session: Session }) => {
    const sourcesUrl = useSourcesPath();
    const dispatch = useAppDispatch();
    const uploadFile = (file: File) => dispatch(fetchUploadFile({ sessionId: session.id, file: file }));
    const fileUploadState = useAppSelector(selectFileUploadState);
    const uploading = fileUploadState === 'loading';

    const uploadLinkModal = useContext(UploadLinkContext);

    const onDrop = (acceptedFiles: File[], fileRejections: FileRejection[], event: DropEvent) => {
        if (acceptedFiles.length >= 1) {
            uploadFile(acceptedFiles[0])
        } else {
            for (const fileRejection of fileRejections) {
                dispatch(
                    enqueueSnackbar({
                        message: `Failed to upload file ${fileRejection.file.name}: ${fileRejection.errors[0].code}`,
                        options: {
                            variant: "error",
                        },
                    })
                );
            }
        }
    }

    const onUploadLinkButtonClicked = (event: React.MouseEvent) => {
        event.stopPropagation();
        uploadLinkModal.onOpen();
    }

    return <>
        <Divider orientation="vertical" />
        <Dropzone onDrop={onDrop} maxFiles={1} maxSize={MAX_FILE_SIZE} accept={ACCEPTED_FILE_TYPES} multiple={false} minSize={0} noClick>
            {({ getRootProps, getInputProps, open }) => (
                <Box className="internal-page-drawer" {...getRootProps()}>
                    <Box className="internal-page-drawer-header">
                        <Typography variant="h3">Sources</Typography>
                        <Button size="small" variant="outlined" color="primary" aria-label="View all sources" component={Link} to={sourcesUrl()}>View all</Button>
                    </Box>
                    <Divider />
                    <Box className="internal-page-drawer-body hide-scrollbar">
                        {session && <SourcesList session={session} />}
                    </Box>
                    <Button className="session-page-upload-box" style={{ backgroundColor: 'transparent' }} disableRipple onClick={open} component={Box}>
                        {uploading ? <CircularProgress /> :
                            <>
                                <Box sx={{ textAlign: "center" }}>
                                    <Typography component="div" color="text.disabled">
                                        <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", gap: "0.5rem" }}>
                                            <FileAddOutlined />
                                            <Typography>Add a file</Typography>
                                        </Box>
                                        <Typography>or</Typography>
                                    </Typography>
                                </Box>
                                <Button onClick={onUploadLinkButtonClicked} aria-label="Add link" variant="contained" color="contrast" startIcon={<AddLinkOutlinedIcon />} >Add a link</Button>
                            </>
                        }
                    </Button>
                </Box>
            )}
        </Dropzone>
    </>
}

const SessionPageInteractionsTab = ({ session, state }: { session: Session, state: SessionState }) => {
    const hasSources = session && ((session.contents?.length > 0) || (session.linked_sessions?.length > 0));
    const hasInteractions = session && (session?.interactions?.length > 0)
    const showPlaceholder = (!hasSources && !hasInteractions)
    return <>
        {hasSources && <SessionPageMobileSources session={session} />}
        {!showPlaceholder && session && <SessionInteractionsGrid session={session} />}
        {showPlaceholder &&
            <Box className="session-placeholder">
                <Typography color="text.secondary"> Add some sources </Typography>
                <Typography color="text.secondary"> to interact with your Brua</Typography>
            </Box>
        }
    </>
}

const SessionPageDetailsTab = ({ session }: { session: Session }) => {
    return <Box className="session-description">
        <Box sx={{ width: "100%", height: "100%" }} >
            <Markdown remarkPlugins={remarkPlugins} rehypePlugins={rehypePlugins} className='markdown-body markdown-body-default session-info-markdown'
            children={preprocessLaTeX(session.description || "")}></Markdown>
        </Box>
    </Box>
}



type UpdateSessionFormParams = {
    name?: string;
    description?: string;
    shortDescription?: string;
    theme?: string;
    basePrompt?: string;
}

const schema = yup.object({
    name: yup.string().min(3).max(SessionNameMaxLength),
    description: yup.string().nullable(),
    shortDescription: yup.string().max(SessionShortDescriptionMaxLength).nullable(),
    basePrompt: yup.string().max(SessionBasePromptMaxLength).nullable(),
    theme: yup.string().oneOf(themeKeys)
}).required();

const SessionPageSettingsTab = ({ session }: { session: Session }) => {
    const dispatch = useAppDispatch();

    const updateSessionState = useAppSelector(selectUpdateSessionState);

    const { control, register, handleSubmit, formState: { errors, dirtyFields }, setValue, watch, reset } = useForm({
        resolver: yupResolver(schema),
        mode: "onSubmit",
        defaultValues: {
            name: session.name,
            description: session.description,
            shortDescription: session.short_description,
            basePrompt: session.base_prompt,
            theme: session.theme || "0"
        }
    });

    const isActuallyDirty = Object.keys(dirtyFields).length > 0;

    const [currentShortDescription, currentBasePrompt] = watch(["shortDescription", "basePrompt"]);

    const sessionIsUpdating = updateSessionState === "loading";

    const save = (data: yup.InferType<typeof schema>) => {
        if (updateSessionState !== "idle" || !isActuallyDirty) {
            return;
        }

        // updateParams gets all fields from data for which dirtyFields[field] is true
        // Make it ts compatible by reducing the keys of dirtyFields
        const updateParams = Object.keys(dirtyFields).reduce((acc, field) => {
            // @ts-ignore
            acc[field] = data[field];
            return acc;
        }, {} as UpdateSessionFormParams);

        dispatch(fetchUpdateSession({ sessionId: session.id, ...updateParams }));
        reset(updateParams);
    }

    const doButtonClick = (theme: string) => () => {
        setValue("theme", theme, { shouldDirty: true });
    }

    const onInvalid = (a: any) => {
        console.log(a)
    }

    return <Box className="session-description" component="form" id="update-session-form" onSubmit={handleSubmit(save, onInvalid)}>

        <ModalFormField title="Name of space">
            <TextField fullWidth {...register("name")} error={!!errors.name} helperText={errors.name?.message || ""} />
        </ModalFormField>
        <ModalFormField title="Short description">
            <TextField
                fullWidth
                inputProps={{
                    maxLength: SessionShortDescriptionMaxLength,
                }}
                placeholder="Describe the space in a few words."
                {...register("shortDescription")} error={!!errors.shortDescription} helperText={errors.shortDescription?.message || ""}
            />
            <Typography sx={{ alignSelf: "flex-end" }} variant="body2" color="text.secondary">{currentShortDescription?.length || 0}/{SessionShortDescriptionMaxLength}</Typography>
        </ModalFormField>
        <ModalFormField title="Theme">
            <Box sx={{ display: "flex", flexDirection: "row", gap: "8px" }}>
                <Controller
                    name="theme"
                    control={control}
                    render={({ field }) =>
                        <>
                            {
                                Object.entries(themes).map(([themeId, background]) => <IconButton key={themeId} onClick={doButtonClick(themeId)} sx={{ padding: 0 }}>
                                    <Box sx={{ padding: "8px", borderRadius: "50%", border: field.value === themeId ? "2px solid white" : "" }}>
                                        <Box key={themeId} style={{ background: background, height: "40px", width: "40px", borderRadius: "50%" }}></Box>
                                    </Box>
                                </IconButton>)
                            }
                        </>
                    }
                />
            </Box>
        </ModalFormField>
        <ModalFormField title="URL">
            <SessionUrlTextField session={session} />
        </ModalFormField>
        <ModalFormField title="Describe your space in more detail">
            <SessionDetailsEditor session={session} onChange={(markdown) => setValue("description", markdown, { shouldDirty: true })} />
        </ModalFormField>
        {spacePromptEnabled && <ModalFormField title="Base prompt">
            <TextField
                multiline
                rows={4}
                fullWidth
                InputProps={{
                    sx: {
                        alignItems: 'start'
                    }
                }}
                {...register("basePrompt")}
                placeholder="Write a prompt to change how the space responds to user queries."
                error={!!errors.basePrompt} helperText={errors.basePrompt?.message || ""}
                inputProps={{ maxLength: SessionBasePromptMaxLength }}
            />
            <Typography sx={{ alignSelf: "flex-end" }} variant="body2" color="text.secondary">{currentBasePrompt?.length || 0}/{SessionBasePromptMaxLength}</Typography>
        </ModalFormField>}
        <Box sx={{ alignSelf: "flex-end" }}>
            <Button size="large" type="submit" form="update-session-form" variant="contained" disabled={sessionIsUpdating || !isActuallyDirty}>
                {sessionIsUpdating ? <CircularProgress size={24} color="inherit" /> : "Save"}
            </Button>
        </Box>
    </Box>
}

const SessionDetailsEditor = ({ session, onChange }: { session: Session, onChange: (markdown: string) => void }) => {
    const { mode } = useColorScheme();

    return <MDXEditor className={`${mode === "dark" ? "dark-theme" : ""}`}
        suppressHtmlProcessing={true}
        onChange={onChange}
        markdown={session.description || ""}
        placeholder="Write a few lines about why you've created this space, where the sources are from, any important themes or topics." plugins={[
            toolbarPlugin({
                toolbarContents: () => <>
                    <DiffSourceToggleWrapper options={['rich-text', 'source']}>
                        <UndoRedo />
                        <Separator />
                        <BlockTypeSelect />
                        <Separator />
                        <BoldItalicUnderlineToggles options={["Bold", "Italic"]} />
                        <CodeToggle />
                        <Separator />
                        <ListsToggle />
                        <Separator />
                        <CreateLink />
                        <InsertImage />
                        <Separator />
                        <InsertTable />
                        <InsertThematicBreak />
                        <Separator />
                        <InsertCodeBlock />
                    </DiffSourceToggleWrapper>
                </>
            }),
            codeBlockPlugin({ defaultCodeBlockLanguage: '' }),
            codeMirrorPlugin({
                codeMirrorExtensions: mode === "dark" ? [basicDark] : undefined,
                codeBlockLanguages: { js: 'JavaScript', css: 'CSS', txt: 'Plain Text', tsx: 'TypeScript', '': 'Unspecified', python: "Python" }
            }),
            listsPlugin(),
            quotePlugin(),
            headingsPlugin(),
            linkPlugin(),
            linkDialogPlugin(),
            imagePlugin(),
            tablePlugin(),
            thematicBreakPlugin(),
            diffSourcePlugin({ viewMode: "rich-text", codeMirrorExtensions: mode === "dark" ? [basicDark] : undefined }),
            markdownShortcutPlugin(),
            htmlPlugin()
        ]} />
}


export const SessionPage = ({ tab }: { tab?: SessionPageTab }) => {
    return <InternalPage>
        <SessionPageBody tab={tab} />
    </InternalPage>
};