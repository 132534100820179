import { useAppDispatch, useAppSelector } from "../../app/hooks";
import Dropzone, { DropEvent, FileRejection } from "react-dropzone";
import { enqueueSnackbar } from "../notifications/notificationsSlice";
import { Box, LinearProgress, Typography } from "@mui/material";
import { Session } from "../../types";
import { fetchUploadFile, selectFileUploadState } from "../session/sessionSlice";

export const MAX_FILE_SIZE = 50 * 1024 * 1024;
export const ACCEPTED_FILE_TYPES = {
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
    'application/pdf': ['.pdf'],
    'application/msword': ['.doc'],
    'text/plain': ['.txt'],
    'text/html': ['.html', '.htm'],
    'image/jpeg': ['.jpg', '.jpeg'],
    'image/png': ['.png']
};

export const FileUploadDropzone = ({ session }: { session: Session }) => {
    const dispatch = useAppDispatch();
    const uploadFile = (file: File) => dispatch(fetchUploadFile({ sessionId: session.id, file: file }));
    const fileUploadState = useAppSelector(selectFileUploadState);
    const uploading = fileUploadState === 'loading';

    const accept = {
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
        'application/pdf': ['.pdf'],
        'application/msword': ['.doc'],
        'text/plain': ['.txt'],
        'text/html': ['.html', '.htm'],
        'image/jpeg': ['.jpg', '.jpeg'],
        'image/png': ['.png']
    };
    const fifty_mb = 50 * 1024 * 1024;

    const onDrop = (acceptedFiles: File[], fileRejections: FileRejection[], event: DropEvent) => {
        if (acceptedFiles.length >= 1) {
            uploadFile(acceptedFiles[0])
        } else {
            for (const fileRejection of fileRejections) {
                dispatch(
                    enqueueSnackbar({
                        message: `Failed to upload file ${fileRejection.file.name}: ${fileRejection.errors[0].code}`,
                        options: {
                            variant: "error",
                        },
                    })
                );
            }
        }
    }


    const dropZone = <Dropzone noClick onDrop={onDrop} maxFiles={1} multiple={false} accept={accept} minSize={0} maxSize={fifty_mb}>
        {({ getRootProps, getInputProps, open }) => (
            <div {...getRootProps()} >
                <input {...getInputProps()} />
                <Box className="file-upload-dropzone" onClick={e => open()} sx={{ color: "var(--mui-palette-text-disabled)" }}>
                    .pdf, .jpeg, .png, docx, ...
                </Box>
            </div>
        )}
    </Dropzone>

    const progress = <Box sx={{ marginLeft: '32px', marginRight: '32px', paddingTop: '32px', textAlign: 'center' }}>
        <Typography>Uploading...</Typography>
        <LinearProgress sx={{ height: 10, borderRadius: 5 }} />
    </Box>


    return <>{uploading ? progress : dropZone}</>
}
