import { useAppSelector } from "../../app/hooks";
import { SessionLoaderComponent } from "../../features/session/sessionLoaderComponent";
import { selectSessionById } from "../../features/session/sessionSlice";
import { SourceCardSmallComponent, SourceCardTinyComponent } from "./sourceCard";
import { getSessionImage } from "../../sessionUtils";
import { useSourcesPath } from "../../hooks/useUrl";

export const LinkedSessionCardSmall = ({ sessionId }: { sessionId: string }) => {

    const session = useAppSelector(selectSessionById(sessionId));
    const origin = window.location.origin

    const sessionUrl = session?.published_session ? `/p/${session?.published_session?.id}` : `/${sessionId}`;


    const icon = ""
    const source = `${origin}/#${sessionUrl}`
    const image = session ? getSessionImage(session) : ""
    const isCurrentReference = false
    const title = session?.published_session?.id || session?.name as string;

    const sourceUrl = useSourcesPath();
    const linkTarget = sourceUrl(sessionId)

    return <>
        <SessionLoaderComponent sessionId={sessionId} />
        <SourceCardSmallComponent icon={icon} source={source} image={image} linkTarget={linkTarget} isCurrentReference={isCurrentReference} title={title} />
    </>
}

export const LinkedSessionCardTiny = ({ sessionId }: { sessionId: string }) => {

    const session = useAppSelector(selectSessionById(sessionId));
    const origin = window.location.origin
    const sessionUrl = session?.published_session ? `/p/${session?.published_session?.id}` : `/${sessionId}`;


    const icon = ""
    const source = `${origin}/#${sessionUrl}`
    const title = session?.published_session?.id || session?.name as string;

    const sourceUrl = useSourcesPath();
    const linkTarget = sourceUrl(sessionId)

    return <>
        <SessionLoaderComponent sessionId={sessionId} />
        <SourceCardTinyComponent icon={icon} source={source} linkTarget={linkTarget} title={title} />
    </>
}