import { Box, CircularProgress, Divider, IconButton, Typography } from "@mui/material";
import { InternalPage, InternalPageContentBodyHeader } from "./internalPage";
import { useSession } from "../hooks/useSession";
import { InteractionSocketConnector } from "../connectors/interactionSocketConnector";
import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import { fetchAddInteractionMember, resetCurrentContentReferences, selectCurrentContentReferences, selectInteraction } from "../features/session/sessionSlice";
import { InteractionContents } from "../components/interaction/interactionContents";
import { Content, Interaction, Session } from "../types";
import ShareOutlinedIcon from '@mui/icons-material/ShareOutlined';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import CloseIcon from '@mui/icons-material/Close';
import { QAInteractionComponent } from "../components/interaction/qaInteractionComponent";
import { InviteInteractionMemberModal } from "../components/modals/inviteInteractionMemberModal";
import { InteractionActionsPopover } from "../components/popover/interactionActionsPopover";
import { usePopover } from "../hooks/usePopover";
import { useModal } from "../hooks/useModal";
import { SourceCardOverlay } from "../components/cards/sourceCard";
import { MembersAvatarGroup, getInteractionMembers } from "../members";
import { SessionSocketConnector } from "../connectors/sessionSocketConnector";
import { useAuth } from "../auth";

const SourcesDrawer = ({ interaction, contents, session }: { interaction: Interaction, contents: Content[], session: Session }) => {
    return <Box className="internal-page-drawer">
        <Box className="internal-page-drawer-header">
            <Typography variant="h3">Referenced sources</Typography>
        </Box>
        <Divider />
        <Box className="internal-page-drawer-body hide-scrollbar">
            <InteractionContents interaction={interaction} contents={contents} session={session} />
        </Box>
    </Box>
}


export const InteractionPageHeaderActions = ({ session, interaction }: { session: Session, interaction: Interaction }) => {
    const { isGuest } = useAuth();

    const popover = usePopover();
    const modal = useModal();

    const dispatch = useAppDispatch();
    const invite = (userId: string) => {
        if (session) {
            dispatch(fetchAddInteractionMember({ sessionId: session.id, interactionId: interaction.id, userId: userId }));
        }
    }

    const canShare = !isGuest

    return <>
        <InteractionActionsPopover sessionId={session.id} interaction={interaction} open={popover.open} onClose={popover.handleClose} anchorEl={popover.anchorEl} />
        <InviteInteractionMemberModal open={modal.open} handleClose={modal.onClose} invite={invite} interaction={interaction} members={session?.members || []} sessionId={session.id} />
        {canShare && <IconButton onClick={modal.onClick} aria-label="invite" color="primary"><ShareOutlinedIcon fontSize="small" /></IconButton>}
        <IconButton onClick={popover.handleClick} aria-label="more-actions" color="primary"><MoreVertIcon fontSize="small" /></IconButton>
    </>
}

const SourcesOverlay = ({ contents }: { contents: Content[] }) => {

    const dispatch = useAppDispatch();

    const currentContentReferences = useAppSelector(selectCurrentContentReferences);
    const selectedContent = currentContentReferences.map(ref => ref.contentId);

    const contentsToDisplay = contents.filter(content => selectedContent.includes(content.id));
    const shouldDisplay = contentsToDisplay.length > 0;

    const onCloseClicked = () => {
        dispatch(resetCurrentContentReferences({}));
    }

    return <>{
        shouldDisplay &&
        <Box className="interaction-page-sources-overlay mobile-only-flex" >
            <IconButton sx={{ position: 'absolute', right: 0, top: 0, zIndex: 1200 }} onClick={onCloseClicked}>
                <CloseIcon />
            </IconButton>
            {contentsToDisplay.map((content, index) => <SourceCardOverlay key={content.id} content={content} index={index} />)}
        </Box>
    }
    </>
}

const InteractionPageHeader = ({ session, interaction }: { session: Session, interaction: Interaction }) => {
    const interactionMembers = getInteractionMembers(interaction, session.members)

    return <InternalPageContentBodyHeader className="interaction-page-header">
        <MembersAvatarGroup members={interactionMembers} max={5} />
        <InteractionPageHeaderActions session={session} interaction={interaction} />
        <SourcesOverlay contents={session.contents} />
    </InternalPageContentBodyHeader >
}

const InteractionPageBody = () => {

    const { interactionId } = useParams();

    const { session, state } = useSession()

    const interaction = useAppSelector(selectInteraction(session?.id, interactionId));

    if (state === 'loading' || !interactionId) {
        return <CircularProgress />
    }

    // const InteractionComponent = interaction?.type === "QUESTION_ANSWERING" ? QAInteractionComponent : TextOutputInteractionComponent;
    const InteractionComponent = QAInteractionComponent;

    return <>
        {session?.id && <SessionSocketConnector sessionId={session?.id} sessionIsLoaded={!!session} />}
        <InteractionSocketConnector interactionId={interactionId} sessionId={session?.id} />
        <Box className="internal-page-center">
            {session && interaction && <InteractionPageHeader interaction={interaction} session={session} />}
            {session && interaction && <InteractionComponent interaction={interaction} session={session} />}
        </Box>
        <Divider orientation="vertical" className="desktop-divider" />
        {session && interaction && <SourcesDrawer interaction={interaction} contents={session?.contents || []} session={session} />}
    </>
}

export const InteractionPage = () => {
    return <InternalPage>
        <InteractionPageBody />
    </InternalPage>
};