import { Divider, TextField, Typography } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { Modal, ModalActions, ModalButton, ModalContents, ModalFormField, ModalHeader } from "./modal";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { selectLastCreatedSessionId, setLastCreatedSessionId } from "../../features/session/sessionSlice";
import { UseModalProps } from "../../hooks/useModal";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { SessionNameMaxLength, SessionShortDescriptionMaxLength } from "../../types";

export const CreateSessionRedirector = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const lastCreatedSession = useAppSelector(selectLastCreatedSessionId)
    useEffect(() => {
        if (lastCreatedSession) {
            dispatch(setLastCreatedSessionId(undefined))
            navigate(`/${lastCreatedSession}`);
        }
    }, [lastCreatedSession, dispatch, navigate]);

    return <></>
}

export const CreateSessionModalContext = React.createContext<UseModalProps | undefined>(undefined);

type FormData = {
    name: string;
    shortDescription: string;
};

export type { FormData as CreateSessionFormData };

const schema = yup.object({
    name: yup.string().required().min(3).max(SessionNameMaxLength),
    shortDescription: yup.string().max(SessionShortDescriptionMaxLength),
}).required();

export const CreateSessionModal = ({ open, onClose, onAccept }: { open: boolean, onClose: () => void; onAccept: (name: string, shortDescription?: string) => void; }) => {
    const { control, handleSubmit, reset, formState: { errors } } = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
            name: ''
        },
        mode: "onSubmit"
    });

    const nameInputRef = React.useRef<HTMLInputElement>(null);

    useEffect(() => {
        if (open) {
            setTimeout(() => nameInputRef.current?.focus(), 10);
        }
    }, [open]);

    const onSubmit = ({ name, shortDescription }: { name: string, shortDescription?: string }) => {
        onAccept(name, shortDescription);
        reset();
    }

    const doOnClose = () => {
        reset();
        onClose();
    }

    return <Modal open={open} onClose={onClose}>
        <ModalContents>
            <ModalHeader>
                Create Space
            </ModalHeader>
            <form onSubmit={handleSubmit(onSubmit)} className="modal-form" id="create-session-modal-form">
                <ModalFormField title="Name">
                    <Controller
                        name="name"
                        control={control}
                        render={({ field }) => <TextField {...field} fullWidth placeholder="Name" inputRef={nameInputRef} error={!!errors.name} helperText={errors.name?.message || ""} />}
                    />
                </ModalFormField>
                <ModalFormField title="Short description (optional)">
                    <Controller
                        name="shortDescription"
                        control={control}
                        render={({ field }) => <>
                            <TextField inputProps={{
                                maxLength: SessionShortDescriptionMaxLength,
                            }} {...field} fullWidth multiline rows={3} error={!!errors.shortDescription} helperText={errors.shortDescription ? `Must be at most ${SessionShortDescriptionMaxLength} characters.` : ""} />
                            <Typography sx={{ alignSelf: "flex-end" }} variant="body2" color="text.secondary">{field.value?.length || 0}/{SessionShortDescriptionMaxLength}</Typography>
                        </>}
                    />
                </ModalFormField>
            </form>
        </ModalContents>
        <Divider />
        <ModalActions>
            <ModalButton onClick={doOnClose} variant="text" color="primary" size="large">Close</ModalButton>
            <ModalButton form="create-session-modal-form" type="submit" variant="contained" color="contrast" size="large">Create</ModalButton>
        </ModalActions>
    </Modal>;
};
