import { datetimeStringToDate } from "./time";
import { Session } from "./types";
import DEFAULT_IMAGE from './images/default_session_image.jpg';

export const getSessionLastUpdated = (session: Session) => {
    const lastContentAdded = [...session.contents].sort((a, b) => b.created_time.localeCompare(a.created_time))[0]?.created_time;
    const lastInteraction = [...session.interactions].sort((a, b) => b.created_time.localeCompare(a.created_time))[0]?.created_time;
    const createdTime = session.created_time;

    const lastUpdated = [lastContentAdded, lastInteraction, createdTime].sort((a, b) => b.localeCompare(a))[0];
    return lastUpdated;
}

export const getSessionsSortedByLastUpdated = (sessions: Session[]) => {
    const sessionsWithLastUpdated = sessions.map((session) => ({ session, lastUpdated: getSessionLastUpdated(session) }));
    sessionsWithLastUpdated.sort((a, b) => b.lastUpdated.localeCompare(a.lastUpdated));
    return sessionsWithLastUpdated.map((sessionWithLastUpdated) => sessionWithLastUpdated.session);

}

export const userIsSessionAdmin = (session: Session, userId?: string) => {
    return session.members.find(member => member.user.id === userId)?.role === "ADMIN";
}

export const userIsSessionContributorOrAdmin = (session: Session, userId?: string) => {
    const role = session.members.find(member => member.user.id === userId)?.role
    return role === "ADMIN" || role === "CONTRIBUTOR";
}

const uniqueElements = (arr: any[]) => {
    return arr.filter((value, index, self) => {
        return self.indexOf(value) === index;
    });
};

const getUniqueImages = (session: Session): string[] => {
    const sortedContent = [...session.contents].sort((a, b) => a.created_time.localeCompare(b.created_time));
    const sortedContentImages = sortedContent.map(content => content.tags.image).filter(image => image !== undefined);
    const uniqueImages = uniqueElements(sortedContentImages);
    return uniqueImages;
}

export const getSessionImage = (session: Session): string => {
    const uniqueImages = getUniqueImages(session);
    return uniqueImages.length > 0 ? uniqueImages[0] : DEFAULT_IMAGE;
}

export const getSessionLinkedTime = (session: Session, linkedSessionId: string) => {
    const linkedActionsDate = session.log.filter(action => action.linked_session_id === linkedSessionId).map(action => datetimeStringToDate(action.datetime))
    const latestDate = new Date(Math.max(...linkedActionsDate.map(date => date.getTime())));
    return latestDate
}