import { useAppSelector } from "../app/hooks";
import { selectSession } from "../features/session/sessionSlice";
import { useParams } from "react-router-dom";
import { usePublishedSession } from "../hooks/usePublishedSession";

export type SessionState = 'loading' | 'loaded' | 'failed';

export const useSession = () => {
    const { sessionId, username, sessionName } = useParams();
    const session = useAppSelector(selectSession(sessionId, username, sessionName));
    const { publishedSessionState } = usePublishedSession({ username, sessionName });
    const isPublished = !sessionId;

    let state: SessionState = 'failed';

    if (publishedSessionState === 'loading' || (!session && publishedSessionState === 'idle')) {
        state = 'loading';
    } else if (session) {
        state = 'loaded';
    } else {
        state = 'failed';
    }

    return { session, state, username, sessionName, isPublished};
}