import { Box, Divider, IconButton, InputAdornment, List, ListItem, ListItemButton, ListItemText, Paper, Popper, TextField, TextFieldProps, Typography } from "@mui/material"
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import { UseSearchEverythingResult } from "../search";
import React, { useEffect } from "react";
import { FuseResult, FuseResultMatch } from "fuse.js";
import { Interaction, Member, Session } from "../../types";
import { usePopover } from "../../hooks/usePopover";
import { ClickAwayListener } from '@mui/base/ClickAwayListener';
import { NavigateFunction, useNavigate } from "react-router-dom";
import { getMemberAvatar, getMemberInfo } from "../../members";
import { getMostPublicSessionPath } from "../../hooks/useUrl";


type toOmit = 'placeholder' | 'variant' | 'fullWidth' | 'label' | 'size';

export const SearchEverythingSearchBar = (props: Omit<TextFieldProps, toOmit>) => {
    return <TextField placeholder="Search everything..." variant="outlined"
        {...props}
        fullWidth
        label=""
        size="small"
        InputProps={{
            ...props.InputProps,
            style: {
                borderRadius: '32px',
            },
            startAdornment: (
                <InputAdornment position="start">
                    <IconButton disabled>
                        <SearchIcon color="inherit" />
                    </IconButton>
                </InputAdornment>
            ),
            endAdornment: (
                <InputAdornment position="start" >
                    <IconButton disableFocusRipple disableRipple disableTouchRipple >
                        <CloseIcon color="inherit" fontSize="small" />
                    </IconButton>
                </InputAdornment>
            ),
        }}
    />
}

interface ResultsPopoverProps {
    sessionResults: FuseResult<Session>[],
    interactionResults: FuseResult<InteractionWithSession>[],
    anchorEl: null | HTMLElement,
    onSessionClicked: (result: FuseResult<Session>) => void,
    onInteractionClicked: (result: FuseResult<InteractionWithSession>) => void,
    onClose: () => void
}

const MemberAvatarWithName = ({ member }: { member: Member }) => {
    const memberInfo = getMemberInfo(member)
    const avatar = getMemberAvatar(member)
    return <Box sx={{ display: 'flex', alignItems: 'center', gap: "12px" }}>
        {avatar}
        <Typography variant="body1" color="text.primary">{memberInfo.displayName}</Typography>
    </Box>
}

const MatchText = ({ match, maxLength }: { match: FuseResultMatch, maxLength?: number }) => {
    const _matchLength = maxLength || match.value?.length || 999999
    const matchLengths = match.indices?.map(([start, end]) => end - start)
    const longestMatchIndex = matchLengths?.reduce((acc, val, idx, arr) => val > arr[acc] ? idx : acc, 0)
    const longestMatch = match.indices?.[longestMatchIndex]

    const matchStart = longestMatch[0]
    const matchEnd = longestMatch[1]
    const start = Math.max(0, matchStart - 10)
    const end = Math.min(start + _matchLength, matchEnd + 10)

    if (matchEnd - matchStart < 5) {
        return <></>
    }

    return <ListItemButton sx={{ pl: 4 }}>
        {match.key?.startsWith('interactions.events.response') ? 'Response: ' : "Query: "} &nbsp;
        <Typography component="span" variant="body1" color="text.secondary">
            {match.value?.slice(start, matchStart)}
        </Typography>
        <strong>{match.value?.slice(matchStart, matchEnd)}</strong>
        <Typography component="span" variant="body1" color="text.secondary">
            {match.value?.slice(matchEnd, end)}...
        </Typography >
    </ListItemButton>
}

const SessionResultItem = ({ result, onClick }: { result: FuseResult<Session>, onClick: (result: FuseResult<Session>) => void }) => {
    const session = result.item;
    const memberMatches = result.matches?.filter(match => match.key?.startsWith('member') && match.refIndex !== undefined);
    const matchedMenberIndexes = memberMatches?.map(match => match.refIndex);
    const matchedMenberIndexesSet = Array.from(new Set(matchedMenberIndexes));
    const matchedMembers = matchedMenberIndexesSet?.map(index => index !== undefined ? session.members[index] : undefined)
    const avatars = matchedMembers?.map(member => member ? <MemberAvatarWithName member={member} /> : undefined)

    const interactionNameMatches = result.matches?.filter(match => match.key?.startsWith('interactions.name') && match.refIndex !== undefined);
    const matchedInteractionNameIndexes = interactionNameMatches?.map(match => match.refIndex);
    const matchedInteractionNameIndexesSet = Array.from(new Set(matchedInteractionNameIndexes));
    const matchedInteractionName = matchedInteractionNameIndexesSet?.map(index => index !== undefined ? session.interactions[index] : undefined)

    const interactionEventsMatches = result.matches?.filter(match => match.key?.startsWith('interactions.events') && match.refIndex !== undefined);

    return <>
        <ListItemButton onClick={() => onClick(result)}>
            {
                session.published_session ?
                    session.published_session.id :
                    <ListItemText primary={result.item.name} />
            }
        </ListItemButton>

        <List disablePadding>
            {avatars.map((avatar, idx) => <ListItemButton onClick={() => onClick(result)} key={idx} sx={{ pl: 4 }}>{avatar}</ListItemButton>)}
        </List>
        <List disablePadding>
            {matchedInteractionName?.map((interaction, idx) => <ListItemButton onClick={() => onClick(result)} key={idx} sx={{ pl: 4 }}>Interaction: {interaction?.name}</ListItemButton>)}
        </List>
        <List disablePadding>
            {interactionEventsMatches?.map((match, idx) => <MatchText key={idx} match={match} />)}
        </List>
    </>
}

export const ResultsComponent = ({ sessionResults, interactionResults, onSessionClicked, onInteractionClicked }: Omit<ResultsPopoverProps, 'anchorEl' | 'onClose'>) => {
    return <List>
        {/* <ListItem>
        <Typography variant="body1" color="text.secondary">Spaces</Typography>
    </ListItem> */}
        {sessionResults.map((result) => <SessionResultItem key={result.item.id} onClick={() => onSessionClicked(result)} result={result}></SessionResultItem>)}
        {interactionResults.length > 0 && false &&
            <>
                <Divider />
                <ListItem>
                    <Typography variant="body1" color="text.secondary">Interactions</Typography>
                </ListItem>
                {interactionResults.map((result) => <ListItemButton key={result.item.id} onClick={() => onInteractionClicked(result)}>{result.item.name}</ListItemButton>)}
            </>
        }
    </List>
}

const ResultsPopover = ({ anchorEl, sessionResults, interactionResults, onSessionClicked, onInteractionClicked, onClose }: ResultsPopoverProps) => {

    const [width, setWidth] = React.useState(0)
    const open = Boolean(anchorEl) && sessionResults.length > 0;

    const doSessionClicked = (result: FuseResult<Session>) => {
        onSessionClicked(result)
        onClose()
    }

    const doInteractionClicked = (result: FuseResult<InteractionWithSession>) => {
        onInteractionClicked(result)
        onClose()
    }

    useEffect(() => {
        if (anchorEl) {
            setWidth(anchorEl?.parentElement?.clientWidth || 0)
        }
    }, [anchorEl])

    return <ClickAwayListener onClickAway={onClose}>
        <Popper
            anchorEl={anchorEl?.parentElement}
            disablePortal
            open={open}
            placement="bottom-start"
            className="hide-scrollbar"
            sx={{ width: width, maxHeight: '80dvh', overflowY: 'auto' }}
        >
            <Paper>
                <ResultsComponent sessionResults={sessionResults} interactionResults={interactionResults} onSessionClicked={doSessionClicked} onInteractionClicked={doInteractionClicked} />
            </Paper>
        </Popper>
    </ClickAwayListener>
}

type InteractionWithSession = Interaction & { session: Session }

export const onSessionNavigate = (navigate: NavigateFunction) => (result: FuseResult<Session>) => {
    navigate(getMostPublicSessionPath(result.item))
}

export const onInteractionNavigate = (navigate: NavigateFunction) => (result: FuseResult<InteractionWithSession>) => {
    navigate(`${getMostPublicSessionPath(result.item.session)}/${result.item.id}`)
}

export type SearchEverythingComponentProps = Omit<TextFieldProps, 'value' | 'onChange'> & UseSearchEverythingResult

export const SearchEverythingComponent = ({ query, setQuery, searchResults, ...props }: SearchEverythingComponentProps) => {

    const { anchorEl, setTarget, handleClose } = usePopover()
    const { sessions: sessionResults, interactions: interactionResults } = searchResults

    const navigate = useNavigate();


    const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setQuery(event.target.value)
        setTarget(event.currentTarget)
    }

    const onSessionClick = (result: FuseResult<Session>) => {
        onSessionNavigate(navigate)(result);
        setQuery('')
    }

    const onInteractionClick = (result: FuseResult<InteractionWithSession>) => {
        onInteractionNavigate(navigate)(result);
        setQuery('')
    }

    return <Box className="search-everything-component">
        <ResultsPopover sessionResults={sessionResults} interactionResults={interactionResults} anchorEl={anchorEl}
            onInteractionClicked={onInteractionClick} onSessionClicked={onSessionClick} onClose={handleClose} />
        <SearchEverythingSearchBar onChange={onChange} value={query} {...props} />
    </Box>
}