import type { MdxJsxAttribute } from 'mdast-util-mdx'
import { mdxJsx } from 'micromark-extension-mdx-jsx'
import { mdxMd } from 'micromark-extension-mdx-md'
import { $createGenericHTMLNode, MdastHTMLNode, MdastImportVisitor, addImportVisitor$, addSyntaxExtension$, isMdastHTMLNode, realmPlugin} from '@mdxeditor/editor'

export const MdastHTMLVisitor: MdastImportVisitor<MdastHTMLNode> = {
  testNode: isMdastHTMLNode,
  visitNode: function ({ mdastNode, actions }): void {
    actions.addAndStepInto($createGenericHTMLNode(mdastNode.name, mdastNode.type, mdastNode.attributes as MdxJsxAttribute[]))
  },
  priority: -10000
}

export const htmlPlugin = realmPlugin({
    init(realm) {
      realm.pubIn({
        // [addMdastExtension$]: [mdxJsxFromMarkdown()],
        [addSyntaxExtension$]: [mdxJsx(), mdxMd()],
        [addImportVisitor$]: MdastHTMLVisitor
      })
    }
  })