import { Box, BoxProps, Typography } from "@mui/material"
import { MembersAvatarGroup } from "../members"
import { Member } from "../types"

const Banner = ({ background, children, color, className }: { background: string } & BoxProps) => {
    const _color = color || "text.dark"
    const _className = `banner ${className || ""}`
    return <Box sx={{
        background: background,
    }}
        color={_color}
        className={_className}
    >
        {children}
    </Box>
}

export const BannerLarge = ({ background, children, color, className }: { background: string } & BoxProps) => {
    const _color = color || "text.dark"
    const _className = `banner-large ${className || ""}`
    return <Banner
        background={background}
        color={_color}
        className={_className}
    >
        {children}
    </Banner>
}

export const BannerSmall = ({ background, children, color, className }: { background: string } & BoxProps) => {
    const _color = color || "text.dark"
    const _className = `banner-small ${className || ""}`
    return <Banner
        background={background}
        color={_color}
        className={_className}
    >
        {children}
    </Banner>
}

export const BannerMembers = ({ members, max = 5}: { members: Member[], max?: number }) => {
    const _members = members || [];

    const avatarMembers = _members.slice(0, max);
    const membersCount = _members.length;
    return <>
        <MembersAvatarGroup members={avatarMembers} spacing="medium" avatarProps={{ style: { border: 'transparent', width: "24px", height: "24px", fontSize: "0.7rem" } }} />
        {<Typography component="span" variant="body2">{`${membersCount} members`}</Typography>}
    </>
}