import { Box, Button, Divider, IconButton, Typography } from "@mui/material";
import { Content, Session, Source } from "../types";
import CloseIcon from '@mui/icons-material/Close';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import ShareOutlinedIcon from '@mui/icons-material/ShareOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import StarOutlinedIcon from '@mui/icons-material/StarOutlined';
import StarBorderOutlinedIcon from '@mui/icons-material/StarBorderOutlined';
import { DisplaySource, SourceMedia } from "./cards/sourceCard";
import { getMemberInfoAvatar } from "../members";
import { dateToDatetimeReadableNoWeekday } from "../time";
import { Link } from "react-router-dom";
import { ConfirmModal, ConfirmModalBody, ConfirmModalStrong, deleteTitle } from "./modals/confirmModal";
import { useModal } from "../hooks/useModal";
import { useAppDispatch } from "../app/hooks";
import { fetchRemoveContent, fetchUnlinkSession, fetchUpdateContent } from "../features/session/sessionSlice";
import { isUrl } from "../urls";
import { getMostPublicSessionPath } from "../hooks/useUrl";
import { useMemo } from "react";
import { sourceFromContent, sourceFromLinkedSession } from "../features/source";

const SourcesPageDrawerHeader = ({ onExit, onDelete, onPrimary, isPrimary, canBePrimary, canEdit }: { canEdit: boolean, onExit: () => void, onDelete: () => void, onPrimary: () => void, isPrimary: boolean, canBePrimary: boolean }) => {
    return <Box className="internal-page-drawer-header">
        <Box>
            <IconButton onClick={onExit} title="Close" aria-label="Close">
                <CloseIcon fontSize="small" />
            </IconButton>
        </Box>
        <Box>
            {canEdit && <IconButton disabled title="Edit" aria-label="Edit">
                <EditOutlinedIcon fontSize="small" />
            </IconButton>}
            <IconButton disabled title="Share" aria-label="Share">
                <ShareOutlinedIcon fontSize="small" />
            </IconButton>
            <IconButton onClick={onPrimary} disabled={!canBePrimary || !canEdit} title="Primary" aria-label="Primary">
                {isPrimary ? <StarOutlinedIcon fontSize="small" /> : <StarBorderOutlinedIcon fontSize="small" />}
            </IconButton>
            {canEdit && <IconButton onClick={onDelete} title="Delete" aria-label="Delete">
                <DeleteOutlinedIcon fontSize="small" color="error" />
            </IconButton>}
        </Box>
    </Box>;
}

export const DrawerContentSourceDetails = ({ content, onExit, session, canEdit }: { content: Content, canEdit: boolean, onExit: () => void, session: Session }) => {
    const dispatch = useAppDispatch()
    const source = useMemo(() => sourceFromContent(content, session),  [content, session])

    const deleteSource = () => {
        dispatch(fetchRemoveContent({ sessionId: session.id, contentId: content.id }))
    }

    const onTogglePrimary = () => {
        dispatch(fetchUpdateContent({ sessionId: session.id, contentId: content.id, isPrimary: !content.is_primary }))
    }


    return <DrawerSourceDetails canBePrimary deleteActionText="delete" canEdit={canEdit} source={source} onExit={onExit} onDelete={deleteSource} onTogglePrimary={onTogglePrimary} />
}

export const DrawerLinkedSessionSourceDetails = ({ linkedSession, onExit, session, canEdit }: { linkedSession: Session, canEdit: boolean, onExit: () => void, session: Session }) => {
    const dispatch = useAppDispatch();

    const url = `${window.location.origin}/#${getMostPublicSessionPath(linkedSession)}`
    console.log(url)

    const source = useMemo(() => sourceFromLinkedSession(linkedSession, session), [linkedSession, session])

    const unlinkSession = () => {
        dispatch(fetchUnlinkSession({ sessionId: session.id, linkedSessionId: linkedSession.id }))
    }

    return <DrawerSourceDetails canBePrimary={false} deleteActionText="unlink" canEdit={canEdit} source={source} onExit={onExit} onDelete={unlinkSession} onTogglePrimary={() => { }} />
}

export const DrawerSourceDetails = ({ source, canEdit, onExit, onDelete, onTogglePrimary, canBePrimary,  deleteActionText }: { source: Source, canBePrimary: boolean, deleteActionText: string, canEdit: boolean; onExit: () => void, onDelete: () => void, onTogglePrimary: () => void }) => {
    const { source: sourceText, title, addedBy: creator, addedTime, author, image, isPrimary, icon, description} = source
    const deleteModal = useModal()
    const sourceIsUrl = source ? isUrl(sourceText) : false

    const avatarWithName = <Box sx={{ display: 'flex', alignItems: 'center', gap: "12px" }}>
        {getMemberInfoAvatar(creator)}
        <Typography variant="body1" color="text.primary">{creator.displayName}</Typography>
    </Box>

    const tags = {
        'Added by': avatarWithName,
        'Uploaded': dateToDatetimeReadableNoWeekday(addedTime),
    }

    const confirmModalBody = <>
        <ConfirmModalBody>Are you sure you want to {deleteActionText} <ConfirmModalStrong>{title}</ConfirmModalStrong>? This action cannot be reversed.</ConfirmModalBody>
    </>

    const onAccept = () => {
        onDelete();
        onExit();
    }

    return <>
        <ConfirmModal open={deleteModal.open} onClose={deleteModal.onClose} onAccept={onAccept}
            title={deleteTitle} body={confirmModalBody} acceptText={`Yes, ${deleteActionText} it`} icon={<DeleteOutlinedIcon />} />

        <SourcesPageDrawerHeader canEdit={canEdit} onExit={onExit} onDelete={deleteModal.onClick} onPrimary={onTogglePrimary} isPrimary={isPrimary} canBePrimary={canBePrimary} />
        <Divider />
        <Box className="sources-page-drawer-body">
            <SourceMedia image={image} sx={{ height: "242px"}} />
            <Box className="sources-page-drawer-body-header">
                <Typography variant="h2">{title}</Typography>
                <Box className="internal-page-drawer-body-subtitle">
                    <Typography variant="body1" color="text.secondary">By {author}</Typography>
                    <DisplaySource source={sourceText} icon={icon} />
                </Box>
                <Typography variant="body1" color="text.secondary">{description}</Typography>
            </Box>
            <Box className="sources-page-drawer-body-metadata">
                {
                    Object.entries(tags).map(([key, value]) => {
                        return <Box key={key} className="sources-page-drawer-body-metadata-item">
                            <Typography variant="body1" color="text.secondary" textTransform="capitalize">{key}</Typography>
                            <Box className="spacing-medium" />
                            <Typography variant="h4">{value}</Typography>
                        </Box>
                    })
                }
            </Box>
        </Box>
        <Divider />
        <Box className="sources-page-drawer-footer">
            {
                sourceIsUrl ?
                    <Button title="View source" aria-label="View source" variant="outlined" size="large" fullWidth startIcon={<VisibilityOutlinedIcon />} component={Link} target="_blank" to={sourceText || ""} >
                        View source
                    </Button>
                    :
                    <Button title="Download source" aria-label="Download source" disabled variant="outlined" size="large" fullWidth startIcon={<FileDownloadOutlinedIcon />} >Download source</Button>
            }
        </Box>
    </>;
};

