import { Box, Typography, styled } from "@mui/material";
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import { useAppDispatch } from "../app/hooks";
import { enqueueSnackbar } from "../features/notifications/notificationsSlice";
import { setClipboardText } from "../features/clipboard";
import { getMostPublicSessionPath } from "../hooks/useUrl";
import { baseUrl } from "../App";
import { Session } from "../types";

const BaseUrlTextField = styled(Box)(({ theme }) => ({
    // @ts-ignore
    border: `1px solid ${theme.palette.border.main}`,
    cursor: 'pointer',
    backgroundColor: 'transparent',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    padding: theme.spacing(2),
    borderRadius: `${theme.shape.borderRadius}px`,
}));

const PrefixTextField = styled(BaseUrlTextField)(({ theme }) => ({
    borderRadius: `${theme.shape.borderRadius}px 0 0 ${theme.shape.borderRadius}px`,
}));

const PostfixTextField = styled(BaseUrlTextField)(({ theme }) => ({
    borderRadius: `0 ${theme.shape.borderRadius}px ${theme.shape.borderRadius}px 0`,
    backgroundColor: theme.palette.background.paper,
    flex: '1 1 auto',
    borderLeft: 0
}));

export const CombinedTextField = styled(BaseUrlTextField)(({ theme }) => ({
    borderRadius: `${theme.shape.borderRadius}px`,
}));

export const UrlTextField = ({ prefix, value }: { prefix?: string; value: string; }) => {
    const dispatch = useAppDispatch();

    const _prefix = prefix + "/";
    const url = (prefix || "") + value;

    const onClick = async () => {
        await setClipboardText(url);
        dispatch(
            enqueueSnackbar({
                message: "Copied URL to clipboard",
                options: {
                    variant: "success",
                },
            })
        );
    }

    if (!prefix) {
        return <BaseUrlTextField onClick={onClick}>
            <Typography variant="body1" color="text.secondary" noWrap>
                {value}
            </Typography>
            <ContentCopyOutlinedIcon fontSize="small" />
        </BaseUrlTextField>
    }


    return <Box sx={{ display: 'flex', width: '100%' }} onClick={onClick}>
        <PrefixTextField>
            <Typography variant="body1" color="text.secondary">
                {_prefix}
            </Typography>
        </PrefixTextField>
        <PostfixTextField>
            <Typography variant="body1" color="text.secondary">
                {value}
            </Typography>
            <ContentCopyOutlinedIcon fontSize="small" />
        </PostfixTextField>
    </Box>;
};

export const SessionUrlTextField = ({ session }: { session: Session }) => {
    const sessionurl = getMostPublicSessionPath(session);
    const url = `${baseUrl}/#${sessionurl}`;
    return <UrlTextField value={url} />
};

