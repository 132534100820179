import { useMemo } from "react";
import { useParams } from "react-router-dom";
import { Session } from "../types";

export const getMostPublicSessionPath = (session: Session) => {
    if (session.published_session) {
        return `/p/${session.published_session.id}`
    }
    return `/${session.id}`
}

export const getContentPath = (baseUrl?: string, contentId?: string ) => {
    return contentId ? `${baseUrl}/sources/${contentId}` : `${baseUrl}/sources`;
}

export const useSessionPath = () => {
    const { sessionId, username, sessionName } = useParams();

    return sessionId ? `/${sessionId}` : (username && sessionName) ? `/p/${username}/${sessionName}` : undefined;
}

export const useSourcesPath = () => {

    const basePath = useSessionPath();

    const sourcesPath = useMemo(() => {
        return (sourceId?: string) => {
            return getContentPath(basePath, sourceId)
        }
    }, [basePath])

    return sourcesPath;
}

export const useInteractionPath = () => {

    const basePath = useSessionPath();

    const interactionPath = useMemo(() => {
        return (interactionId: string, sessionId: string) => {
            if (basePath) {
                return `${basePath}/${interactionId}`
            }
            return `/${sessionId}/${interactionId}`
        }
    }, [basePath])

    return interactionPath;
}